import React, { useEffect } from 'react';
import ReactDOMClient from 'react-dom/client';
import polylineLib from '@mapbox/polyline';

import { mapgl, setMapLayerData } from 'components/RideMap/mapUtils';
import { createGeoJSONCircle, distanceMeters } from 'services/utils';
import { LocationsPopup, EventsPopup } from './Popups';

function setPointerOnHover(map, layerId) {
  const { style } = map.getCanvas();

  function mouseEnter() {
    style.cursor = 'pointer';
  }
  function mouseLeave() {
    style.cursor = '';
  }

  map.on('mouseenter', layerId, mouseEnter);
  map.on('mouseleave', layerId, mouseLeave);

  return () => {
    map.off('mouseenter', layerId, mouseEnter);
    map.off('mouseleave', layerId, mouseLeave);
  };
}

function getEventColor(event) {
  switch (event.type) {
    case 'SHOCK':
      return 'green';
    case 'SCENARIO_TRANSITION': // Legacy.
    case 'TRANSITION':
      switch (event.features.transitionToState) {
        case 'protection_emergency':
          return 'red';
        case undefined: // Legacy.
          return 'orange';
        default:
          return 'black';
      }
    case 'STOP':
    case 'STOP_AFTER_SHOCK':
    case 'SPEED_STOP':
      return 'blue';
    case 'EMERGENCY_REQUEST': // Legacy.
    case 'EMERGENCY':
      return 'red';
    case 'CALL_112':
      return 'red';
    case 'SURVEY_ANSWER':
    case 'ACCIDENT_SURVEY': // Legacy.
      return 'blue';
    case 'PAUSE':
      return 'cyan';
    case 'RESUME':
      return 'cyan';
    default:
      return 'black';
  }
}

const EVENTS_SOURCE_ID = 'debugevents_source';
const CLUSTER_CIRCLES_LAYER_ID = 'debugevents_cluster_layer';
const CLUSTER_COUNTS_LAYER_ID = 'debugevents_count_layer';
const EVENTS_MARKERS_LAYER_ID = 'debugevents_markers_layer';
const EVENTS_POLYLINE_SOURCE_ID = 'debugevents_polyline_source';
const EVENTS_POLYLINE_LAYER_ID = 'debugevents_polyline_layer';

function showEventsPopup(map, features, clusterRadius) {
  if (!features || !features.length) {
    return;
  }

  const element = document.createElement('div');
  ReactDOMClient.createRoot(element).render(<EventsPopup features={features} />);
  new mapgl.Popup({
    closeOnClick: true, maxWidth: 'none', anchor: 'bottom', offset: clusterRadius,
  })
    .setLngLat(features[0].geometry.coordinates)
    .setDOMContent(element)
    .addTo(map);
}

export function useMapDebugEvents(map, events) {
  useEffect(() => {
    if (!map || !events) {
      return undefined; // No Cleanup callback.
    }

    const validEvents = events.filter((event) => event.location !== null);
    const geojson = {
      type: 'FeatureCollection',
      features: validEvents.map((event) => {
        const r = Math.random(); // FIXME: no random, align points with horizontal offset
        return {
          type: 'Feature',
          properties: {
            // JSON properties are silently converted to flat strings.
            eventType: event.type,
            ...event,
            ...event.location,
            features: JSON.stringify(event.features) || undefined, /* Querying JSON properties
            is unpredicatble, so it's best to serialize ourselves. */
            color: getEventColor(event),
          },
          geometry: {
            type: 'Point',
            coordinates: [
              event.location.longitude + Math.cos(r * Math.PI * 2) * 0.00001,
              event.location.latitude + Math.sin(r * Math.PI * 2) * 0.00001,
            ],
          },
        };
      }),
    };

    const source = map.getSource(EVENTS_SOURCE_ID);
    const clusterRadius = 20;
    if (source) {
      source.setData(geojson);
    } else {
      map.addSource(EVENTS_SOURCE_ID, {
        type: 'geojson',
        data: geojson,
        cluster: true,
        // clusterMaxZoom: 16,
        clusterRadius,
      });
    }

    const layer = map.getLayer(CLUSTER_CIRCLES_LAYER_ID);
    if (!layer) {
      map.addLayer({
        id: CLUSTER_CIRCLES_LAYER_ID,
        type: 'circle',
        source: EVENTS_SOURCE_ID,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#000',
          'circle-opacity': 0.3,
          'circle-radius': clusterRadius,
        },
      });

      map.addLayer({
        id: EVENTS_MARKERS_LAYER_ID,
        type: 'circle',
        source: EVENTS_SOURCE_ID,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': ['get', 'color'],
          'circle-opacity': 0.6,
          'circle-radius': ['interpolate', ['linear'], ['zoom'], 17, 6, 20, 12],
          'circle-stroke-width': 1.5,
          'circle-stroke-color': '#000',
        },
      });

      const linestring = {
        type: 'LineString',
        coordinates: geojson.features.map((feature) => feature.geometry.coordinates),
      };
      setMapLayerData({
        map,
        layerId: EVENTS_POLYLINE_LAYER_ID,
        sourceId: EVENTS_POLYLINE_SOURCE_ID,
        style: {
          type: 'line',
          paint: {
            'line-color': '#000',
            'line-width': 2,
            'line-opacity': 0.5,
            'line-dasharray': [1, 1],
          },
        },
        geojson: linestring,
      });

      map.addLayer({
        id: CLUSTER_COUNTS_LAYER_ID,
        type: 'symbol',
        source: EVENTS_SOURCE_ID,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-size': 18,
          'text-font': ['Noto Sans Regular'],
        },
        paint: {
          'text-halo-width': 1.5,
          'text-halo-color': '#ffffff',
        },
      });

      // Cluster circle interaction.
      map.on('click', CLUSTER_CIRCLES_LAYER_ID, (e) => {
        e.preventDefault();

        const [cluster] = map.queryRenderedFeatures(
          e.point,
          { layers: [CLUSTER_CIRCLES_LAYER_ID] },
        );
        const clusterId = cluster.properties.cluster_id;

        const clusterSource = map.getSource(EVENTS_SOURCE_ID);
        clusterSource.getClusterExpansionZoom(clusterId).then((zoom) => {
          map.easeTo({
            center: cluster.geometry.coordinates,
            zoom: Math.max(map.getZoom(), zoom - 0.1),
          });
        }).catch(console.error); // eslint-disable-line no-console

        clusterSource.getClusterLeaves(clusterId, 999, 0).then((features) => {
          showEventsPopup(map, features, clusterRadius);
        }).catch(console.error); // eslint-disable-line no-console
      });

      // Marker interaction.
      map.on('click', EVENTS_MARKERS_LAYER_ID, (e) => {
        e.preventDefault();

        const p = e.point;
        const features = map.queryRenderedFeatures([[p.x - 5, p.y - 5], [p.x + 5, p.y + 5]], {
          layers: [EVENTS_MARKERS_LAYER_ID],
        });

        showEventsPopup(map, features, clusterRadius);
      });
    }

    const clearPointer1 = setPointerOnHover(map, CLUSTER_CIRCLES_LAYER_ID);
    const clearPointer2 = setPointerOnHover(map, EVENTS_MARKERS_LAYER_ID);

    return () => {
      clearPointer1();
      clearPointer2();
      map.removeLayer(CLUSTER_CIRCLES_LAYER_ID);
      map.removeLayer(CLUSTER_COUNTS_LAYER_ID);
      map.removeLayer(EVENTS_MARKERS_LAYER_ID);
      map.removeLayer(EVENTS_POLYLINE_LAYER_ID);
    };
  }, [map, JSON.stringify(events)]);
}

const LOCATIONS_SOURCE_ID = 'debuglocations_source';
const CIRCLES_LAYER_ID = 'debuglocations_circles_layer';
const DISCS_LAYER_ID = 'debuglocations_discs_layer';

function showLocationsPopup(map, { features, lngLat, startTime }) {
  if (!features || !features.length) {
    return;
  }

  const element = document.createElement('div');
  ReactDOMClient.createRoot(element).render(
    <LocationsPopup
      features={features}
      map={map}
      DISCS_LAYER_ID={DISCS_LAYER_ID}
      startTime={startTime}
    />,
  );
  new mapgl.Popup({ closeOnClick: true, maxWidth: 'none', anchor: 'bottom' })
    .setLngLat(lngLat)
    .setDOMContent(element)
    .addTo(map);
}

export function useMapDebugLocations(map, { debugLocations, detailedPolyline, startTime }) {
  useEffect(() => {
    if (!map || !debugLocations) {
      return undefined; // No Cleanup callback.
    }

    const geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    let cumulativeDistance = 0;
    let previousLocation = null;
    const locations = (debugLocations.length > 0)
      ? debugLocations
      : polylineLib.decode(detailedPolyline).map((coords, index) => ({
        latitude: coords[0],
        longitude: coords[1],
        index: `~${index}`, // Deduced, may not match what the mobile uploaded.
      }));
    locations.forEach((location) => {
      if (!location.isApproximate) {
        if (previousLocation) {
          cumulativeDistance += distanceMeters(previousLocation, location);
        }
        previousLocation = location;
      }
      geojson.features.push({
        ...createGeoJSONCircle(
          [location.longitude, location.latitude],
          location.accuracy || 5,
          8,
        ),
        properties: {
          ...location, distance: cumulativeDistance, color: location.isApproximate ? 'red' : 'blue',
        },
      });
    });

    setMapLayerData({
      map,
      layerId: CIRCLES_LAYER_ID,
      sourceId: LOCATIONS_SOURCE_ID,
      style: {
        type: 'fill',
        paint: {
          'fill-color': 'rgba(0, 0, 0, 0)',
          'fill-outline-color': ['get', 'color'],
          'fill-opacity': 0.8,
        },
      },
      geojson,
    });
    setMapLayerData({
      map,
      layerId: DISCS_LAYER_ID,
      sourceId: LOCATIONS_SOURCE_ID,
      style: {
        type: 'fill',
        paint: {
          'fill-color': '#000',
          'fill-opacity': 0.7,
        },
      },
      geojson,
    });
    map.setFilter(DISCS_LAYER_ID, ['==', 'index', -1]);

    map.on('click', /* All layers because we use a bounding box. */ (e) => {
      if (e.defaultPrevented) {
        return; // Another event had a higher priority.
      }

      const p = e.point;
      const features = map.queryRenderedFeatures(
        [[p.x - 3, p.y - 3], [p.x + 3, p.y + 3]],
        { layers: [CIRCLES_LAYER_ID] },
      );

      showLocationsPopup(map, { features, lngLat: e.lngLat, startTime });
    });

    const clearPointer = setPointerOnHover(map, CIRCLES_LAYER_ID);

    return () => {
      clearPointer();
      map.removeLayer(CIRCLES_LAYER_ID);
      map.removeLayer(DISCS_LAYER_ID);
    };
  }, [map, debugLocations, detailedPolyline]);
}
